import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from '../src/app/App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(
    <App />
    , document.getElementById('root')
)

reportWebVitals()
